import React from "react"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

import logo from "../../images/logo_afdt.png";

const Menu = () => (
    <Navbar className="bg-dark-blue" expand="lg">
        <div className="container">
            <Navbar.Brand href="/">
                <img src={logo} alt="logo au fil du toit" className="img-fluid" />
            </Navbar.Brand>
            <Navbar.Toggle style={{ color: "white", borderColor: "white" }}>
                <FontAwesomeIcon icon={faBars} />
            </Navbar.Toggle>
            <Navbar.Collapse id="my-navbar">
                <Nav className="ml-auto">
                    <div className="desktop-link">
                        <div className="nav-item dropdown">
                            <div className="nav-link dropdown-toggle" href="#">NOS SOLUTIONS</div>
                            <div className="dropdown-menu">
                                <div className="dropdown-item dropdown">
                                    <a href="/recherche-de-fuites-et-diagnostics" className="dropdown-toggle">Recherches de fuites et diagnostics</a>
                                    <div className="dropdown-menu submenu">
                                        <a className="dropdown-item " href="/recherche-de-fuites-et-diagnostics/recherche-de-fuites-sur-toiture">
                                            Recherche de fuites sur toiture
                                        </a>
                                        <a className="dropdown-item " href="/recherche-de-fuites-et-diagnostics/diagnostics">
                                            Diagnostics
                                        </a>
                                    </div>
                                </div>
                                <div className="dropdown-item dropdown">
                                    <a href="/entretien" className="dropdown-toggle">Entretien</a>
                                    <div className="dropdown-menu submenu">
                                        <a className="dropdown-item " href="/entretien/entretien-toiture-industrielle">
                                            Entretien toiture industrielle
                                        </a>
                                        <a className="dropdown-item " href="/entretien/entretien-toiture-traditionnelle">
                                            Entretien toiture traditionnelle
                                        </a>
                                        <a className="dropdown-item " href="/entretien/entretien-toit-terrasse">
                                            Entretien toit terrasse
                                        </a>
                                    </div>
                                </div>
                                <div className="dropdown-item dropdown">
                                    <a href="/petits-travaux" className="dropdown-toggle">Petits travaux</a>
                                    <div className="dropdown-menu submenu">
                                        <a className="dropdown-item " href="/petits-travaux/reparation">
                                            Réparation
                                        </a>
                                        <a className="dropdown-item " href="/petits-travaux/isolation">
                                            Isolation
                                        </a>
                                        <a className="dropdown-item " href="/petits-travaux/apport-de-lumiere-naturelle">
                                            Apport de lumière naturelle
                                        </a>
                                        <a className="dropdown-item " href="/petits-travaux/desenfumage">
                                            Désenfumage
                                        </a>
                                        <a className="dropdown-item " href="/petits-travaux/acces-difficiles">
                                            Accès difficiles
                                        </a>
                                        <a className="dropdown-item " href="/petits-travaux/securisation">
                                            Sécurisation
                                        </a>
                                        <a className="dropdown-item " href="/petits-travaux/amiante">
                                            Amiante
                                        </a>
                                    </div>
                                </div>
                                <div className="dropdown-item dropdown">
                                    <a href="/renovation" className="dropdown-toggle">Rénovation</a>
                                    <div className="dropdown-menu submenu">
                                        <a className="dropdown-item " href="/renovation/renovation-toiture-industrielle">
                                            Rénovation toiture industrielle
                                        </a>
                                        <a className="dropdown-item " href="/renovation/renovation-toiture-traditionnelle">
                                            Rénovation toiture traditionnelle
                                        </a>
                                        <a className="dropdown-item " href="/renovation/renovation-etancheite-toit-terrasse">
                                            Rénovation étanchéité toit terrasse
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="desktop-link">
                        <div className="nav-item dropdown">
                            <div className="nav-link dropdown-toggle" href="#">NOS AGENCES</div>
                            <div className="dropdown-menu">
                                <a className="dropdown-item" href="/notre-agence-de-reparation-et-renovation-toitures-montpellier">Montpellier</a>
                                <a className="dropdown-item" href="/notre-agence-de-reparation-et-renovation-toitures-nimes">Nîmes</a>
                                <a className="dropdown-item" href="/notre-agence-de-reparation-et-renovation-toitures-beziers">Béziers</a>
                                <a className="dropdown-item" href="/demarche-qualite-et-rse">Démarche Qualité &amp; RSE</a>
                                <a className="dropdown-item" href="/raison-etre">Raison d'être</a>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-link">
                        <NavDropdown title="NOS SOLUTIONS">
                            <NavDropdown.Item href="/recherche-de-fuites-et-diagnostics">Recherches de fuites et diagnostics</NavDropdown.Item>
                            <NavDropdown.Item href="/entretien">Entretien</NavDropdown.Item>
                            <NavDropdown.Item href="/petits-travaux">Petits travaux</NavDropdown.Item>
                            <NavDropdown.Item href="/renovation">Rénovation</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="NOS AGENCES">
                            <NavDropdown.Item href="/notre-agence-de-reparation-et-renovation-toitures-montpellier">Montpellier</NavDropdown.Item>
                            <NavDropdown.Item href="/notre-agence-de-reparation-et-renovation-toitures-nimes">Nîmes</NavDropdown.Item>
                            <NavDropdown.Item href="/notre-agence-de-reparation-et-renovation-toitures-beziers">Béziers</NavDropdown.Item>
                            <NavDropdown.Item href="/demarche-qualite-et-rse">Démarche Qualité &amp; RSE</NavDropdown.Item>
                            <NavDropdown.Item href="/raison-etre">Raison d'être</NavDropdown.Item>
                        </NavDropdown>
                    </div>
                    <Nav.Item>
                        <Nav.Link href="/realisations">REALISATIONS</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/blog">BLOG</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/avis">AVIS</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/contact">CONTACT</Nav.Link>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </div>
    </Navbar>
)

export default Menu;