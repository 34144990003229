import React, { useState } from "react"
import PropTypes from "prop-types"
import { Alert } from "react-bootstrap"
import Cookies from "universal-cookie"

import "../styles/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, ariane }) => {
  const cookies = new Cookies();
  const [show, setShow] = useState(cookies.get("window") ? false : true);

  function handleClose() {
    cookies.set("window", false, { path: "/" });
    setShow(false);
  }

  return (
    <>
      <Header ariane={ariane} />
      <main>{children}</main>
      <Alert show={show} onClose={handleClose} dismissible variant="light" style={{ marginBottom: "0", backgroundColor: "#32334e", borderRadius: "0", color: 'white' }} className="fixed-bottom">
        <div className="container">
          <p>
            En poursuivant votre navigation sur notre site, vous acceptez l’utilisation de Cookies pour nous permettre d'établir des statistiques de visites et ainsi améliorer notre site selon votre utilisation
					</p>
          <a href="/mentions_legales" style={{ color: 'white' }}>En savoir plus</a>
          <button style={{ marginLeft: '2rem' }} className="btn-green" variant="light" onClick={handleClose}>OK</button>
        </div>
      </Alert>
      <Footer />
    </>
  )
}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
