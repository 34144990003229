import React from "react"

import arrow from "../../images/layout/fleche.svg"

const Ariane = ({ ariane }) => {

    let linksJSX = []
    if (ariane) {
        ariane.forEach((item, index) => {
            if (ariane.length - 1 === index) {
                linksJSX.push(
                    <div key={`ariane-${index}`}>
                        <p>{item.title}</p>
                    </div>
                )
            } else {
                linksJSX.push(
                    <div key={`ariane-${index}`}>
                        <a href={item.url} >{item.title.toUpperCase()}</a>
                        {/* <FontAwesomeIcon icon={faChevronRight} className="ml-2 text-secondary" /> */}
                        <img src={arrow} alt="arrow logo" />
                    </div>
                )
            }
        })
    }

    return (
        <div className="ariane">
            <div className="container">
                <div className="d-flex align-items-center">
                    {linksJSX}
                </div>
            </div>
        </div>
    )
}

export default Ariane;